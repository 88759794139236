@use "sass:math";

@mixin min-height($hmin) {
  @media (min-height: $hmin) {
    @content;
  }
}

@mixin max-height($hmax) {
  @media (max-height: $hmax) {
    @content;
  }
}

@mixin min-width($wmin) {
  @media (min-width: $wmin) {
    @content;
  }
}

@mixin max-width($wmax) {
  @media (max-width: $wmax) {
    @content;
  }
}

@function rem($pixels) {
  @return math.div($pixels, 16) * 1rem;
}

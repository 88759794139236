.fdc-investments {
  .block-bg-image {
    // Mobile
    @media (max-width: 768px) {
      position: relative;
      width: 100% !important;
      height: 90vw;
    }
  }

  .investment {
    &-figs {
      // Mobile
      @media (max-width: 768px) {
        .block-bg-image {
          height: 75vw;
        }
      }
    }

    &-coinbase {
      .block-bg-image {
        left: -25% !important;
      }

      // Desktop
      @media (min-width: 1024px) {
        .block-bg-image {
          div {
            width: 130%;
            position: absolute;
            right: 0;
          }
        }
      }

      // Tablet
      @media (min-width: 768px) {
        .block-bg-image {
          left: 0% !important;
          div {
            width: 180%;
            position: absolute;
            right: 0;
          }
        }
      }

      // Mobile
      @media (max-width: 768px) {
        .block-group {
          background-color: #1652f0;
        }

        .block-bg-image {
          order: 1;
          height: fit-content;
          left: 0 !important;
        }
      }
    }

    &-gimlet {
      // Mobile
      @media (max-width: 768px) {
        .block-bg-image {
          order: 1;
        }
      }
    }

    &-public {
      // TODO: Add Block linear gradient functionality, or pull these colors from client-colors somewhere
      background: linear-gradient(-45deg, #00f, #00b7ff);
      // Mobile
      @media (max-width: 768px) {
        .block-bg-image {
          order: 1;
          height: 75vw;
          margin-top: -100px;
        }
      }
    }

    &-getaway {
      // Mobile
      @media (max-width: 768px) {
        .block-bg-image {
          order: 1;
          height: 70vw;
          margin-top: -25px;
        }
      }
    }

    &-jackpocket {
      // Mobile
      @media (max-width: 768px) {
        .block-bg-image {
          height: 95vw;

          div {
            position: absolute;
            right: 0;
            width: 150%;
          }
        }

        .block-group {
          margin-top: -100px;
        }
      }

      @media (max-width: 425px) {
        .block-group {
          margin-top: -20px;
        }
      }
    }
  }
}

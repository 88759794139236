@use "sass:math";

@tailwind base;
@tailwind components;
@tailwind utilities;

// Old Breakpoints
$breakpoints: (
  xxs: 256,
  xs: 512,
  s: 768,
  m: 1024,
  l: 1440,
  xl: 1632,
  xxl: 2560,
  xxxl: 3200,
);

$bp-xs: 320px;
$bp-mobile: 480px;
$bp-s: 768px;

@import "./mixins";
@import "./diagonal";
@import "./fonts";
@import "./type";
@import "./investments";
@import "./home";
@import "./angle";

// component-specific styles
@import "../components/styles/index.scss";

@layer base {
  html,
  body {
    height: 100%;
  }

  #__next {
    min-height: 100%;
    @apply flex flex-col;
  }

  html {
    @apply font-sans;
    @apply selection:bg-brand-700;
    @apply selection:text-white;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
  }

  // Unordered List
  ul {
    padding-left: 1.5rem;

    li {
      position: relative;
      line-height: 1.5;
      padding-left: 1.25rem;
      margin-top: 0.25em;
      @apply body-rg;

      &:before {
        @apply bg-brand-500;
        content: "";
        left: -1.25rem;
        position: absolute;
        width: 1.25rem;
        height: 2px;
        margin-top: -1px;
        top: 0.75em;
      }

      .bg-brand-500 * {
        &:before {
          @apply bg-white;
        }
      }
    }
  }

  .bg-brand-500 li::before {
    @apply bg-light #{!important};
  }

  // Ordered List
  ol {
    padding-left: 1.25rem;
    counter-reset: item;

    li {
      position: relative;
      line-height: 1.5;
      padding-left: 1.25rem;
      margin-top: 0.25em;
      @apply body-rg;

      &:before {
        left: -1.25rem;
        position: absolute;
        width: 1.25rem;
        counter-increment: item;
        content: counter(item) ".";
        font-variant-numeric: tabular-nums;
        font-weight: 400;
        height: auto;
        top: auto;
      }
    }
  }

  .list-none li::before {
    content: unset;
  }

  // Landscape mobile edge case styling
  footer {
    // Small
    @include max-width($bp-s) {
      // landscape mobile
      @include max-height($bp-mobile) {
        height: 100% !important;
        flex-direction: row !important;

        > a {
          height: auto !important;
        }
      }
    }
  }
}

$xxlmargin: calc((((100vw - theme("screens.2xl")) / 2) + theme("spacing.20")) * -1);
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .constrained,
  .constrained-nav {
    @apply w-full mx-auto px-6 2xl:max-w-screen-2xl;
  }

  .constrained {
    @apply lg:px-14 xl:px-20;
  }

  // outside-constrained is to be used INSIDE the constrained div, for elements that belong inside
  // semantically but should take up the whole width of the page, like backgrounds or image elements
  .outside-constrained {
    @apply -ml-6 -mr-6 w-screen lg:-ml-14 lg:-mr-14 xl:-ml-20 xl:-mr-20;
    @screen 2xl {
      margin-left: $xxlmargin;
      margin-right: $xxlmargin;
    }
  }

  // remove-constrained undoes the constrained class so can be used for responsiveness
  // e.g. constrained lg:remove-constrained 2xl:constrained
  .remove-constrained {
    @apply px-0 mx-0 w-auto max-w-full;
  }

  .list-fennel {
    @apply pl-5 text-lg lg:text-xl;

    li {
      @apply pl-3;

      &:before {
        content: "\2726";
        color: #00e691;
        @apply top-0 h-auto bg-transparent;
      }
    }
  }

  .list-none {
    padding: 0;

    li {
      padding: 0;

      &::before {
        position: unset;
        top: unset;
        left: unset;
        background: unset;
        height: unset;
      }
    }
  }

  // the way this is written means you can use it as a standalone link link-arrow-[left/right]
  // but also with group-hover like link-arrow-[right/left] group-hover:link-arrow-[right/left]
  .link-arrow {
    &-right,
    &-left {
      @apply inline-block relative;

      &::before,
      &::after {
        @apply font-sans ease-in-out opacity-0 transition-all duration-300;
      }
    }

    &-right {
      &::after {
        @apply inline-block absolute right-0;
        content: "\2192";
      }
      @apply hover:link-arrow-right-active;
    }

    &-right-active {
      &::after {
        @apply opacity-100 translate-x-[110%];
      }
    }

    &-left {
      &::before {
        @apply inline-block absolute left-0;
        content: "\2190";
      }
      @apply hover:link-arrow-left-active;
    }

    &-left-active {
      &::before {
        @apply opacity-100 -translate-x-[110%];
      }
    }
  }
}

.cover {
  transform: translateY(-100%);
}

button:disabled {
  opacity: 0.5;
}

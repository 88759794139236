@charset 'UTF-8';

// Constants

// Ratio of what % of the height the square should be
$fixed-square-ratio: calc(430 / 900);
$fixed-square-size: $fixed-square-ratio * 100vh;

// Ratio of what % of the height the square should be
$mobile-square-ratio: calc(320 / 414);
$mobile-square-size: $mobile-square-ratio * 100vw;

// ----- Intro ----- //

//$home-intro-square-delay: 2810ms;
//$home-intro-square-in: 700ms;

//$easing: cubic-bezier(1, 0, 0.65, 1);

// ----- Mixins ----- //

// Desktop
//@mixin fixed-slides {
//@media only screen and (min-width: $bp-s), (min-aspect-ratio: (0.9)), (min-height: $bp-mobile) {
//  @content;
// }
//}

// Mobile
@mixin mobile-slides {
  @include max-width($bp-mobile) {
    @content;
  }
}

// Header styling
.page--home {
  .u-header__bg {
    visibility: hidden;
  }
}

// ==== Home ================================
// Shared styling
// ==========================================

// Hide the nav bar when possible (just chrome right now)
::-webkit-scrollbar {
  display: none;
}

.v-home {
  // Why: to hide the iOS safari viewport resizing, otherwise a bit of
  // red will peek for a second when the navbar hides on scroll
  background: #161616 center no-repeat;

  // Spacer for scroll sizing & animation trigger points
  &__block {
    height: 100vh;
    height: 100lvh;
    width: 100vw;

    &.block__half {
      height: 50vh;
    }
  }

  // Full screen slide
  &__slide {
    align-items: center;
    content-visibility: auto;
    display: flex;
    height: -webkit-fill-available;
    height: 100vh;
    height: 100lvh;
    justify-content: center;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100vw;

    // Mobile
    @include max-width($bp-mobile) {
      min-height: 134vw; // WHY: Limits the slides from overflowing on square screens
      //  #{--mobile-square-size}: #{$mobile-square-size};

      // .m-block__content {
      //   padding: 0;
      //  }
    }
  }

  // Backgrounds
  &__background {
    background-position: center center;
    background-size: cover;
    content-visibility: auto;
    height: 100vh;
    height: 100lvh;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &__square {
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    height: $fixed-square-size;
    opacity: 0;
    position: absolute;
    width: $fixed-square-size;
    z-index: 1;

    // Mobile
    @include max-width($bp-mobile) {
      height: $mobile-square-size;
      width: $mobile-square-size;
    }
  }

  &__text {
    font-size: calc(1rem + 8vw);
    line-height: 1;
    margin: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    z-index: 3;

    // Small
    @include max-width($bp-s) {
      font-size: calc(1rem + 12vw);
      width: 90%;

      // landscape mobile
      @include max-height($bp-mobile) {
        font-size: calc(1rem + 7vw);
      }
    }

    // Mobile
    // @include max-width($bp-mobile) {
    //  font-size: calc(1rem + 6vw);
    // }

    // Xtra small
    //@include max-width($bp-xs) {
    //  font-size: calc(1rem + 5vw);
    //}
  }

  // Info sections on the bottom of the slides
  &__details {
    bottom: 3.125rem;
    display: flex;
    grid-row: 3;
    justify-content: center;
    left: 5%;
    position: fixed;
    width: 90%;
    z-index: 2;

    // Small
    @include max-width($bp-s) {
      // landscape mobile
      @include max-height($bp-mobile) {
        bottom: 1rem;
      }
    }
  }

  // Slide scroll-progress bar
  &__scroller {
    height: 100vh;
    height: 100lvh;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 1.875rem;
    top: 0;
    width: 0.625rem;

    // Small
    @include max-width($bp-s) {
      height: -webkit-fill-available;
      height: 100dvh;
      left: 0.9375rem;
      width: 0.375rem;
    }

    .scroller {
      height: 70%;
      margin-top: 15vh;
      overflow: hidden;
      position: relative;

      // Small
      @include max-width($bp-s) {
        height: 50%;
        margin-top: 23vh;
      }

      &__track {
        height: 100%;
        opacity: 0.5;
        pointer-events: auto;
        width: 100%;
      }

      &__bar {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}

// Hide the nav bar when possible (just chrome right now)
::-webkit-scrollbar {
  display: none;
}

// Helper
// used on project type list items
.h-ttn {
  text-transform: none;
}

.main {
  z-index: 3;
}

// Why: to hide the iOS safari viewport resizing, otherwise a bit of
// red will peek for a second when the navbar hides on scroll
.v-home {
  background: #161616 center no-repeat;
}

.v-home-projects {
  // Spacer for scroll sizing & animation trigger points
  &__block {
    height: 100vh;
    height: 100lvh;
    pointer-events: none;
    width: 100vw;

    // Shortened height on the first slide's block, so transition to second happens sooner
    &:nth-of-type(1) {
      height: 60vh;
    }

    // Lengthened the last block so the progress bar finishes before secrets section comes in
    // TODO: change the 7 if slides are added or removed
    &:nth-of-type(7) {
      margin-bottom: 50vh;
    }
  }

  // Project slides: hold all the content for each project
  &__slide {
    align-items: center;
    content-visibility: auto;
    display: flex;
    height: 100vh;
    height: -webkit-fill-available;
    height: 100lvh;
    justify-content: center;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100vw;

    //  @include max-width($bp-mobile) {
    //   min-height: 134vw; // WHY: Limits the slides from overflowing on square screens
    //  #{--mobile-square-size}: #{$mobile-square-size};

    //  .m-block__content {
    //    padding: 0;
    //  }
    // }

    &.whitehouse {
      .v-home-projects__square {
        scale: 1.4;
      }
    }
  }

  &__intro {
    @apply bg-brand-500;
    z-index: 2;
    height: 100%;
    position: relative;

    // Small
    /* @include max-width($bp-s) {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    } */

    // Not small
    @include min-width($bp-s) {
      height: 100vw;
      margin-top: -50vw;
      position: fixed;
      top: 50%;
      width: 100vw;
      left: 0;

      //  .page--loaded & {
      //    transform: scale(var(--home-intro-square-scale));
      //   transition: transform $home-intro-duration cubic-bezier(1, 0, 0.65, 1);
      // }
    }

    &-chunk {
      background: center / cover;
      display: block;
      height: calc(150 / 1280 * 108%);
      left: -4%; // WHY: Sizes the SVG chunks to match the logo width/height
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 108%;
      z-index: 5;

      &--logo {
        @apply text-light;
        height: 100%;
        left: 12.5%;
        top: 0;
        transform: none;
        width: 75%;
        margin: 0 auto;
      }

      &--square {
        @apply bg-dark;
        height: 100%;
        left: 0;
        opacity: 1;
        top: 0;
        transform: scale(0);
        transform-origin: center; // WHY: Compensates for the translation for centered scaling
        width: 100%;
        z-index: 2;
      }

      .page--loaded & {
        // &--we {
        //  animation: home-intro-chunk-opacity-in $home-intro-we-in $easing,
        //     home-intro-chunk-opacity-out $home-intro-we-out $home-intro-we-in $easing;
        // }

        // &--are {
        //   animation: home-intro-chunk-opacity-in $home-intro-are-in $home-intro-are-delay
        //       $easing,
        //    home-intro-chunk-opacity-out $home-intro-are-out
        //      ($home-intro-are-delay + $home-intro-are-in) $easing;
        // }

        // &--logo {
        //  animation: home-intro-chunk-opacity-in $home-intro-logo-in $home-intro-logo-delay
        //    $easing both;
        // }

        // &--square {
        //  animation: home-intro-chunk-square-in $home-intro-square-in $home-intro-square-delay
        //    $easing both;
      }
    }
  }

  // Backgrounds
  &__background {
    background-position: center center;
    background-size: cover;
    content-visibility: auto;
    height: 100vh;
    height: 100lvh;
    opacity: 0;
    position: absolute;
    transform: scale(1.4);
    width: 100%;
    z-index: -1;

    // Hero
    &#bg-0 {
      background: #161616;
      opacity: 1;
    }

    // Wirecutter
    &#bg-1 {
      visibility: visible;
    }

    // Apple
    &#bg-2 {
      background-color: #2537ac;
      background-image: url("/images/home/projects/bgs/bg-apple.jpg");
    }

    // Warby Parker
    &#bg-warby-3 {
    }

    // MGM
    &#bg-4 {
      background-color: #000000;
      background-image: url("/images/home/projects/bgs/bg-mgm.jpg");
    }

    // Riteaid
    &#bg-5 {
      background: linear-gradient(to bottom right, #e62542, #c8102e 40%, #950b38);
    }

    // Crunchbase
    &#bg-6 {
      background: linear-gradient(170deg, darken(#b6d3e2, 5%) 10%, #e6e9ef 40%, #9faad3);
      background-attachment: fixed;
    }
    &#bg-7 {
      background-color: #af161b;
      background-image: url("/images/home/projects/bgs/bg-whitehouse.png");
    }
  }

  // Squares
  &__square {
    background-position: center center;
    background-size: cover;
    box-sizing: border-box;
    height: $fixed-square-size;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    width: $fixed-square-size;
    z-index: 1;

    // Mobile
    @include max-width($bp-mobile) {
      height: $mobile-square-size;
      width: $mobile-square-size;
    }

    &#sq-0 {
      @apply border-brand-500;
      opacity: 1;
      visibility: visible;
      z-index: 3;

      // Mobile
      @include min-width($bp-mobile) {
        // border-width: 7px;
        // grid-row: 2;
      }
    }

    // Wirecutter: square image is slightly taller
    &#sq-1 {
      height: $fixed-square-size * 1.2;

      // Mobile
      @include max-width($bp-mobile) {
        height: $mobile-square-size * 1.2;
      }
    }

    // Apple: drop shadow
    &#sq-2 {
      box-shadow: 0 0 80px 0 rgba(176, 137, 204, 0.25);
    }

    // Warby: For focus effect
    &#sq-3 {
      pointer-events: auto;
    }
  }

  // Text Headings
  .v-home__text {
    @apply text-light;
    pointer-events: none;
    visibility: hidden;

    // Wirecutter: blue drop shadow
    &#text-1 {
      // WHY: iOS Safari refuses to display a text-shadow with variable font sizes so
      //      this pseudo element is used instead to create the suggestion of a drop shadow
      &::before {
        color: #133091;
        content: attr(data-heading);
        filter: blur(#{0.85vh * 1.33});
        height: 100%;
        left: 0.85vh;
        padding: inherit;
        position: absolute;
        top: 0.85vh;
        user-select: none;
        width: 100%;
      }

      .m-heading__span {
        position: relative;
      }
    }

    // White House: drop shadow
    &#text-7 {
      &::before {
        color: #00000025;
        content: attr(data-heading);
        filter: blur(#{0.85vh * 1.33});
        height: 100%;
        left: -0.25vh;
        padding: inherit;
        position: absolute;
        top: 1.85vh;
        user-select: none;
        width: 100%;
      }

      .m-heading__span {
        position: relative;
      }
    }

    // Warby: focus effect
    &.text__warby {
      color: transparent;
      max-width: none;
      position: relative;
      width: 100%;

      &.before,
      &.after {
        #{--warby-cut}: #{calc($fixed-square-size / 2)};
        color: #2b343e;
        content: attr(data-heading);
        height: 100%;
        left: 0;
        padding: inherit;
        position: absolute;
        top: 0;
        user-select: none;
        width: 100%;

        // Mobile
        @include max-width($bp-mobile) {
          /* #{--warby-cut}: #{calc($mobile-square-ratio * 100vw / 2)}; */
        }
      }

      // Outside the square
      &.before {
        clip-path: polygon(
          0% 0%,
          calc(50% - var(--warby-cut)) 0%,
          calc(50% - var(--warby-cut)) 100%,
          calc(50% + var(--warby-cut)) 100%,
          calc(50% + var(--warby-cut)) 0%,
          100% 0%,
          100% 100%,
          0% 100%
        );
        filter: blur(0.2941176471vw);
        transition: filter 1000ms ease-in;
      }

      // Unblur outside text when hovering on slide 3 (warby)
      // This works because slide content default has no pointer events,
      // but for warby the square is the only element with pointer events
      @at-root {
        #s-3:hover {
          .text__warby.before {
            filter: blur(0);
            transition-timing-function: cubic-bezier(0.12, 0.57, 0.7, 0.8);
          }
        }
      }

      // Inside the square
      &.after {
        clip-path: polygon(
          calc(50% - var(--warby-cut)) 0%,
          calc(50% + var(--warby-cut)) 0%,
          calc(50% + var(--warby-cut)) 100%,
          calc(50% - var(--warby-cut)) 100%
        );
      }
    }

    span {
      position: relative;
    }

    // Crunchbase: color change
    &#text-6 {
      color: #485083;
    }
  }

  // Info about each project at the bottom of the screen
  &__details {
    bottom: 1.5rem;
    grid-row: 3;
    left: 5%;
    position: fixed;
    width: 90%;
    z-index: 2;

    // Small
    @include max-width($bp-s) {
      // landscape mobile
      @include max-height($bp-mobile) {
        bottom: 0rem;
      }
    }
  }

  // Intro blurb
  &__blurb {
    bottom: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    visibility: hidden;
    width: 100%;

    p {
      margin-left: auto;
      margin-right: auto;
      max-width: 39.334em; // WHY: 944px / 24pt from the designs;

      @media (max-height: 660px) and (max-width: 640px) {
        @apply text-sm;
      }
    }
  }

  &__info {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    font-size: 0.6em;
    margin-right: auto;
    margin-top: auto;
    padding-top: 2.9411764706vw;
    pointer-events: auto;
    position: absolute;
    visibility: hidden;
    width: 100%;
    z-index: 10;

    // Mobile
    @include max-width($bp-mobile) {
      font-size: 0.6em;
      left: 0;
      text-align: center;
      width: 100%;
    }

    // XS
    @include max-width($bp-xs) {
      font-size: 0.5em;
    }
  }

  // The logos in the info sections
  &__logos {
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;
    opacity: 0;

    // Small
    @include max-width($bp-s) {
      flex-direction: column;

      // landscape mobile
      @include max-height($bp-mobile) {
        flex-direction: row;
        margin-bottom: 1rem;
      }
    }

    svg {
      width: 175px;
      height: 40px;
      margin: 1rem 1rem 0;
    }
    &--whitehouse {
      svg {
        width: 175px;
        height: 50px;
        margin: 1rem 1rem 0;
      }
      @include min-width($bp-s) {
        svg {
          height: 60px;
        }
      }
    }
  }

  &__types {
    padding: 0;

    h6 {
      text-transform: uppercase;
      opacity: 0;
      display: inline-block;
      font-size: 0.8rem;

      & > :nth-child(n) {
        display: inline-block;
      }

      & > :not(:last-child) {
        margin-right: 0.5em;

        &::after {
          content: "•";
          display: inline-block;
          margin-left: 0.5em;
        }
      }

      pre {
        margin: 0 4px;
      }
    }
  }
}

.v-home-misc {
  // What's our secret? section
  &__secret {
    align-items: center;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
    display: flex;
    flex-direction: column;
    opacity: 1;
    padding: 5%;
    position: relative;
    visibility: visible;
    z-index: 3;

    // Small
    @include max-width($bp-s) {
      padding: 10% 5%;
    }

    // An exception to keep this slide visibile at all times since it's clipped by the parent path
    // But turn pointer events off
    .v-home__slide {
      pointer-events: none;
      visibility: visible;
    }

    .title {
      opacity: 1;
      padding: 15.625rem 0;
      position: relative;

      // Small
      @include max-width($bp-s) {
        padding: 9.375rem 0;
      }
    }

    .square {
      opacity: 1;

      path {
        stroke-dasharray: 2000;
      }
    }

    .step {
      display: grid;
      grid-gap: 5%;
      grid-template-columns: 20% 65%;
      justify-content: center;
      margin: 5%;
      opacity: 1;
      width: 65%;
      z-index: 3;

      // @include max-width(bp(l)) {
      //   width: 75%;
      // }

      // @include max-width(bp(m)) {
      //   width: 85%;
      // }

      // Small
      @include max-width($bp-s) {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 15%;
        text-align: center;
        width: 95%;
      }

      &__number {
        margin: 0;
        font-size: 12.5rem;

        // Small
        @include max-width($bp-s) {
          font-size: 9.375rem;
          margin-bottom: 1.25rem;
        }
      }

      &__heading {
        margin: 0;
        opacity: 1;
        position: relative;

        // Small
        @include max-width($bp-s) {
          width: 100%;
        }
      }

      &__copy {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
      }

      &__wrapper {
        width: calc($fixed-square-size - 10%);
        margin: 0%;

        @include max-width($bp-s) {
          width: calc($mobile-square-size - 5%);
        }
        @apply xl:-ml-20 3xl:ml-0 4xl:ml-48;
      }

      &__cta {
        // Small
        @include max-width($bp-s) {
          margin: 3.125rem auto;
        }
      }
    }
  }

  // ---------- PRESS ----------
  &__press {
    pointer-events: none;

    .press {
      &__bg {
        background-image: url("/images/home/misc/press-bg.jpg");
        content-visibility: auto;
        transform: scale(1.4);
      }

      &__sq {
        background-image: url("/images/home/misc/press-sq.png");
        // opacity: 1;
        -webkit-filter: drop-shadow(12px 12px 25px rgba(0, 0, 0, 1));
        filter: drop-shadow(12px 12px 25px rgba(0, 0, 0, 1));
      }

      &__text {
        -webkit-filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.7));
        filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.7));
      }

      &__cta {
        border: solid;
        bottom: 3.125rem;
        opacity: 0;
        pointer-events: auto;
        position: absolute;
        visibility: hidden;
        z-index: 2;
      }
    }
  }

  // ---------- INVEST ----------
  &__invest {
    pointer-events: none;

    .invest {
      &__sq {
        background-image: url("/images/home/misc/invest-sq.png");
        background-repeat: no-repeat;
        background-size: contain;
        content-visibility: auto;
        height: 100vh;
        height: 100lvh;
      }

      &__bg {
        background-image: url("/images/home/misc/invest-bg.jpg");
        content-visibility: auto;
        transform: scale(1.4);
      }

      &__cta {
        border: solid;
        bottom: 3.125rem;
        opacity: 0;
        pointer-events: auto;
        position: absolute;
        visibility: hidden;
        z-index: 2;
      }
    }
  }
}

.press__cta,
.invest__cta {
  border: solid;
  bottom: 0;
  opacity: 0;
  pointer-events: auto;
  position: absolute !important;
  visibility: hidden;
  z-index: 2;
}

.v-home__block {
  &.invest {
    height: 200vh;
    height: 200lvh;
  }
}

@layer components {
  .fdc {
    &-label:has(input[type="checkbox"]:checked, input[type="radio"]:checked) {
      @apply transition-colors ease-linear;
    }
    &-label:has(input[type="checkbox"]:checked, input[type="radio"]:checked) &-label-text {
      @apply opacity-100;
    }
  }

  .fdc-input-field {
    @apply bevel-clip-full focus:bevel-clip-none h-full w-full max-w-full px-[18px] py-[14px] text-dark outline-none
       transition-all duration-300 placeholder:uppercase placeholder:transition-all placeholder:duration-300 focus:placeholder:opacity-0;

    &-wrapper {
      @apply wrapper-bevel-clip-full focus-within:bevel-clip-none h-[52px] bg-dark bg-opacity-20 p-[2px] transition-all duration-300 focus-within:bg-opacity-40;
    }
    &-error {
      @apply bg-brand-500 bg-opacity-100 focus-within:bg-opacity-100;
    }
    &-error-text {
      @apply text-brand-400 transition-all duration-300;
    }
    &-label {
      @apply block translate-y-2 justify-between uppercase opacity-0 transition-all duration-300 group-focus-within:translate-y-0 group-focus-within:opacity-100;

      &-active {
        @apply translate-y-0 opacity-100;
      }
    }
  }
}

@layer utilities {
  // for it's bevel-like shape
  .bevel-clip-left {
    clip-path: polygon(
      10.5px 0px,
      0px 10.5px,
      0px calc(100% - 10.5px),
      10.5px 100%,
      100% 100%,
      100% 100%,
      100% 0px,
      100% 0px
    );
  }

  .bevel-clip-right {
    clip-path: polygon(
      0px 0px,
      0px 0px,
      0px 100%,
      0px 100%,
      calc(100% - 10.5px) 100%,
      100% calc(100% - 10.5px),
      100% 10.5px,
      calc(100% - 10.5px) 0px
    );
  }

  .bevel-clip-full {
    clip-path: polygon(
      10.5px 0px,
      0px 10.5px,
      0px calc(100% - 10.5px),
      10.5px 100%,
      calc(100% - 10.5px) 100%,
      100% calc(100% - 10.5px),
      100% 10.5px,
      calc(100% - 10.5px) 0px
    );
  }

  .bevel-clip-none {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%, 100% 100%, 100% 100%, 100% 0, 100% 0);
  }

  .wrapper-bevel-clip-full {
    clip-path: polygon(
      12px 0px,
      0px 12px,
      0px calc(100% - 12px),
      12px 100%,
      calc(100% - 12px) 100%,
      100% calc(100% - 12px),
      100% 12px,
      calc(100% - 12px) 0px
    );
  }
}

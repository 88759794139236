$width: 2rem;
$height: 2rem;
$rhombus: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

.fdc-radio {
  height: $height;
  width: $width;
  @apply relative appearance-none;
  &::after,
  &::before {
    height: $height;
    width: $width;
    content: " ";
    position: absolute;
    clip-path: $rhombus;
  }

  &::before {
    @apply bg-dark opacity-20;
  }
  &::after {
    @apply scale-[0.85] bg-white;
  }
  &-label {
    &:has(input[type="radio"]:checked) {
      .fdc-radio__toggle {
        @apply rotate-45 scale-100;
      }
    }
  }
}

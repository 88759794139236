$diagonal-offset-sm: rem(50);
$diagonal-offset: rem(100);

@supports ((-webkit-clip-path: inset(50%)) or (clip-path: inset(50%))) {
  .diagonal-section-top-down.diagonal-section-bottom-down {
    padding-top: calc($diagonal-offset-sm / 2);
    padding-bottom: calc($diagonal-offset-sm / 2);
    clip-path: polygon(
      0% 0%,
      100% $diagonal-offset-sm,
      100% 100%,
      0% calc(100% - $diagonal-offset-sm)
    );

    @screen lg {
      padding-top: calc($diagonal-offset / 2);
      padding-bottom: calc($diagonal-offset / 2);
      clip-path: polygon(0% 0%, 100% $diagonal-offset, 100% 100%, 0% calc(100% - $diagonal-offset));
    }
  }

  .diagonal-section-top-down {
    padding-top: calc($diagonal-offset-sm / 2);
    clip-path: polygon(0% 0%, 100% $diagonal-offset-sm, 100% 100%, 0% 100%);

    @screen lg {
      padding-top: calc($diagonal-offset / 2);
      clip-path: polygon(0% 0%, 100% $diagonal-offset, 100% 100%, 0% 100%);
    }
  }

  .diagonal-section-top-up {
    clip-path: polygon(0% $diagonal-offset-sm, 100% 0%, 100% 100%, 0% 100%);

    @screen lg {
      clip-path: polygon(0% $diagonal-offset, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  .diagonal-section-top-up.diagonal-section-bottom-down {
    clip-path: polygon(
      0% $diagonal-offset-sm,
      100% 0%,
      100% 100%,
      0% calc(100% - $diagonal-offset-sm)
    );

    @screen lg {
      clip-path: polygon(0% $diagonal-offset, 100% 0%, 100% 100%, 0% calc(100% - $diagonal-offset));
    }
  }

  .diagonal-section-bottom-down {
    padding-bottom: calc($diagonal-offset-sm / 2);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - $diagonal-offset-sm));

    @screen lg {
      padding-bottom: calc($diagonal-offset / 2);
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - $diagonal-offset));
    }
  }

  .diagonal-section-bottom-up {
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - $diagonal-offset-sm), 0% 100%);

    @screen lg {
      clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - $diagonal-offset), 0% 100%);
    }
  }

  .diagonal-section-bottom-up.diagonal-section-top-down {
    clip-path: polygon(
      0% 0%,
      100% $diagonal-offset-sm,
      100% calc(100% - $diagonal-offset-sm),
      0% 100%
    );

    @screen lg {
      clip-path: polygon(0% 0%, 100% $diagonal-offset, 100% calc(100% - $diagonal-offset), 0% 100%);
    }
  }

  .diagonal-section-top-up.diagonal-section-bottom-up {
    clip-path: polygon(
      0% $diagonal-offset-sm,
      100% 0%,
      100% calc(100% - $diagonal-offset-sm),
      0% 100%
    );

    @screen lg {
      clip-path: polygon(0% $diagonal-offset, 100% 0%, 100% calc(100% - $diagonal-offset), 0% 100%);
    }
  }

  *[class^="diagonal-section"] {
    // min-height needs to be ATLEAST offset x 2 so the diagonals render properly
    // I've set it to x3 minimum to improve the UI in the case of not enough content
    // ideally the content will always be long enough to not have any UI issues
    min-height: calc($diagonal-offset-sm * 3);

    // a negative margin is set to prevent spacing between sections (caused by the clip-path/padding)
    + [class^="diagonal-section"] {
      margin-top: calc(($diagonal-offset-sm + 1px) * -1);
    }

    @screen lg {
      min-height: calc($diagonal-offset * 3);

      + [class^="diagonal-section"] {
        margin-top: calc(($diagonal-offset + 1px) * -1);
      }
    }
  }
}

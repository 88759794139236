.fdc-switch-input {
  &::before {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  }

  &::after {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  }

  &__toggle {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }

  &:checked ~ &__toggle {
    @apply translate-x-[1.7rem] rotate-[90deg] bg-brand-500 opacity-100;
  }
}

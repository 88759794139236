:root {
  --angle: 5;
  --angle-spacing: calc(1.7345132743vw * var(--angle));
  --angle-clip-path: polygon(
    0 calc(1.7345132743 * var(--angle) * 100vw / 100 - 1px),
    0 100%,
    100% 100%,
    100% 0
  );
}

.p-angle {
  padding-top: var(--angle-spacing);

  + h1,
  + h2,
  + h3,
  + h4,
  + h5,
  + h6 {
    margin-top: 0;
  }
}

.angle-mask {
  clip-path: var(--angle-clip-path);
  margin-top: calc(-1 * var(--angle-spacing));
}

@font-face {
  font-family: "gtc";
  font-weight: 400;
  src: url("../public/fonts/gtc/400.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: "gtc";
  font-weight: 400;
  font-style: italic;
  src: url("../public/fonts/gtc/400i.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: "gtc";
  font-weight: 600;
  src: url("../public/fonts/gtc/600.woff2") format("woff2");
  font-display: fallback;
  ascent-override: 80%;
}

@font-face {
  font-family: "utd-cond";
  font-weight: 400;
  src: url("../public/fonts/utd-cond/400.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: "utd-cond";
  font-weight: 400;
  font-style: italic;
  src: url("../public/fonts/utd-cond/400i.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: "utd-cond";
  font-weight: 500;
  src: url("../public/fonts/utd-cond/500.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: "utd-cond";
  font-weight: 600;
  src: url("../public/fonts/utd-cond/600.woff2") format("woff2");
  font-display: fallback;
}

.project {
  // Reveal mode
  &-reveal {
    .arrow-icon {
      transition: transform 250ms;
      transform: rotate(0deg);
    }

    .project-description {
      transition: all 300ms;
      max-height: 0px;
    }

    &:hover {
      &:not(.project-reveal--open) {
        .graphic-parallax {
          &--small {
            transform: translateY(15px);
          }
          &--large {
            transform: translateY(100px);
          }
        }
      }
    }

    &--open {
      .arrow-icon {
        transform: rotate(180deg);
      }

      .project-description {
        max-height: 750px;
      }

      .graphic-parallax {
        &--small {
          transform: translateY(40px);
        }
        &--large {
          transform: translateY(200px);
        }
      }
    }
  }

  .graphic {
    transition: all 250ms;

    > span {
      height: 100% !important;
      width: 100% !important;
    }

    img {
      object-position: bottom;
    }
  }
}
